@import url('https://fonts.googleapis.com/css2?family=Montagu+Slab&display=swap');

/* For Both Screen Sizes */
#loading-svg {
  width: 38%;
  left: 26%;
}

#nav-bar li{
  cursor: pointer;
}

/* NAV */
.active-nav {
  pointer-events: none;
}

/* HOME */
.hero-text {
  position: absolute;
  left: 55%;
  height: 100%;
  width: 50vw;
  top: 15%;
}

.hero-stroke {
  opacity: .7;
}

.my-name-wrapper {
  position: absolute;
  top: 10%;
  padding-left: 1rem;
}

.hi {
  font-size: 1.4rem;
  letter-spacing: 1px;
  color: #fff;
}

.my-name {
  font-size: 3rem;
  letter-spacing: 3px;
  margin-top: .5rem;
  color: #eef6f6;
}

.my-title {
  font-size: 1.6rem;
  margin-top: .9rem;
  color: #fff;
  letter-spacing: 2px;
}

.explore-button-wrapper {
  position: absolute;
  width: 60%;
  top: 40%;
}

.explore-button {
  opacity: .9;
  background-color: #db888e;
  color: #2c1a1b;
  border: 0 solid #E5E7EB;
  box-sizing: border-box;
  color: #000000;
  display: flex;
  font-family: 'Futura';
  font-size: 1rem;
  font-weight: 700;
  justify-content: center;
  line-height: 1.75rem;
  position: relative;
  text-align: center;
  text-decoration: none #000000 solid;
  text-decoration-thickness: auto;
  width: 100%;
  max-width: 460px;
  cursor: pointer;
  transform: rotate(-2deg);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  padding: .75rem 3rem;
  letter-spacing: 1px;
}

.explore-button:focus {
  outline: 0;
}

.explore-button:after {
  content: '';
  position: absolute;
  border: 1px solid #000000;
  bottom: 4px;
  left: 4px;
  width: calc(100% - 1px);
  height: calc(100% - 1px);
}

.explore-button:hover:after {
  bottom: 2px;
  left: 2px;
}

.explore-tip {
  position: absolute;
  width: 100%;
  top: 30%;
  font-size: 1.3rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #5d3700;
  opacity: 0;
  text-align: center;
}

/* APPLICATIONS */

.app-hero-text {
  letter-spacing: 2px;
}

.app-subhero-wrapper {
  letter-spacing: 1px;
}

#app-hero-item-2 .app-subhero-wrapper {
  color: #fff;
}

#app-hero-item-2 .app-cta {
  color: #fff038;
}

.app-cta {
  cursor: pointer;
}

.app-cta i {
  transition: transform 250ms;
}

.app-cta:hover i{
  transform: translateX(5px);
}

.app-transition {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 25;
  top: 0;
  right: -100%;
  display: none;
}

#apps-0 {
  background-color: #fafafa;
}

#apps-1 {
  background-color: #f9e9e1;
}

#apps-2 {
  background-color: #fafafa;
}

.app-content {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 20;
  bottom: -100%;
  display: none;
  overflow-y: scroll;
}

.app-content-container {
  padding: 0 6.25%;
  width: 100%;
}

.app-nav-bar {
  width: 100%;
  position: relative;
  padding: 1.5rem 1rem;
  display: flex;
  margin-left: 2rem;
}

.app-nav-back {
  cursor: pointer;
  
}

.app-nav-prev {
  position: absolute;
  list-style: none;
  width: 100px;
}

.app-nav-prev-arrow {
  position: absolute;
  width: 16px;
  height: 16px;
  border: 4px solid #262626;
  transition: .5s;
  top: 55%;
  left: 40%;
  transform: translateY(-50%) rotate(-45deg);
  border-right: none;
  border-bottom: none;
  border-radius: 3px;
}

.app-nav-prev:hover span {
  left: 20%
}

.app-nav-prev-arrow:before {
  content: '';
  position: absolute;
  width: 25px;
  height: 3px;
  background: #262626;
  transform-origin: left;
  transform: rotate(45deg) translate(-12px, 7px) scaleX(0);
  transition: .5s;
  border-radius: 3px;
}

.app-nav-prev:hover .app-nav-prev-arrow:before {
  transform: rotate(45deg) translate(-12px, 7px) scaleX(1);
}

.app-nav-prev-back {
  letter-spacing: 1px;
  font-weight: 600;
}

.app-content-article {
  padding-top: 12%;
}

.app-content-box {
  position: relative;
  margin: 0 auto;
}

.app-content-box-container {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: normal;
  justify-content: flex-start;
}

#apps-0 .app-content-feature-subwrapper h3 {
  color: #946449;
}

#apps-1 .app-content-feature-subwrapper h3 {
  color: #2fe2b7;
}

#apps-2 .app-content-feature-subwrapper h3 {
  color: #c89638;
}

.app-content-feature-subwrapper {
  margin-bottom: 10px;
  letter-spacing: 2px;
}

#apps-0 .app-content-heading-wrapper h1{
  color: #27a3e4;
}
#apps-1 .app-content-heading-wrapper h1{
  color: #fb436b;
}

#apps-2 .app-content-heading-wrapper h1{
  color: #386ac8;
}

.app-content-heading-wrapper h1 {
  letter-spacing: 2px;
}

.app-content-info-wrapper {
  margin-bottom: 20px;
  letter-spacing: 1px;
} 

#apps-0 .app-content-info-wrapper h4 {
  color: #60bcd9;
  cursor: pointer;
  transition: all .5s;
}

#apps-0 .app-content-tech-wrapper {
  color: #60bcd9;
}
#apps-1 .app-content-tech-wrapper {
  color: #fc6c94;
}
#apps-2 .app-content-tech-wrapper {
  color: #203d73;
}

#apps-0 .app-content-info-wrapper h4:hover {
  color: #845e66;
}

#apps-1 .app-content-info-wrapper h4 {
  color: #fc6c94;
  cursor: pointer;
  transition: all .5s;
}

#apps-1 .app-content-info-wrapper h4:hover {
  color: #845e66;
}

#apps-2 .app-content-info-wrapper h4 {
  color: #203d73;
  cursor: pointer;
  transition: all .5s;
}

#apps-2 .app-content-info-wrapper h4:hover {
  color: #845e66;
}

.app-content-tech-wrapper{
  margin-bottom: 20px;
  letter-spacing: 1px;
}


.app-content-info-wrapper i {
  opacity: 0;
  transition: all .5s;
}

.app-content-info-wrapper a {
  text-decoration: none;
}

.app-content-info-wrapper:hover i{
  opacity: 1;
  color: #845e66;
}

.app-content-description {
  line-height: 1.5em;
  font-weight: 400;
}

.app-content-description a {
  text-decoration: none;
}

.app-content-description li {
  margin-left: 1rem;
  padding-left: .5rem;
}

#apps-1 .app-content-tech-stack {
  color: #864d23;
}

#apps-0 .app-content-tech-stack {
  color: #454035;
}

#apps-2 .app-content-tech-stack {
  color: #203d73;
}

.app-content-tech-stack {
  list-style-type: none;
  margin-top: 10px
}

.app-content-tech-stack h4{
  margin-bottom: 5px;
}

.app-content-tech-stack li {
  margin-bottom: 8px;
  font-size: .8rem;
}

.app-content-image-wrapper {
  width: 100%;
}

.app-content-image-wrapper img {
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

/* small carousel */
.apps-image-subtext {
  font-weight: 600;
  letter-spacing: 1px;
}

.apps-image-subcontent-2 p{
  color: #fff;
}

/* ABOUT */

#about-wrapper{
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  display: flex;
  flex-direction: column;
  
}

.about-container {
  padding: 0px 6.25%;
  width: 100%;
  flex: 1 1 auto;
  overflow-y: auto;
  height: 0px;
}

.about-sections {
  margin: 25% auto 15% auto;
}

.about-intro {
  color: #000;
  padding: 0 5%;
  justify-content: center;
  align-items: center;
  line-height: 1.75;
  letter-spacing: 1px;
  font-size: 1.15rem;
}

.about-intro p {
  margin: 1rem auto;
}

.about-intro a {
  text-decoration: none;
  color: #1a3455;
  font-weight: 600;
}

.about-tools-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
}

.about-tools-item {
  text-align: center;
  width: 30%;
  color: #f0535d;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.about-testimonials {
  position: relative;
}

.about-testimonials-wrapper h2{
  /* color: #f0535d; */
  color: #35517a;
}

.about-testimonials-wrapper h3{
  font-size: 1.8rem;
  margin-top: .5rem;
}

.about-testimonials-container {
  display: flex;
  flex-direction: column;
  letter-spacing: 1px;
  /* gap: 1rem; */
}

.about-testimonial {
  display: flex;
  flex-wrap: wrap;
}

.about-testimonial-content-wrapper {
  padding: 2rem;
  display: flex;
  flex-direction: column;
}

.about-testimonial-content-wrapper p{
  line-height: 1.75;
}

.about-testimonial-p-top {
  margin: 0 0 1rem;
}

.about-testimonial-opening-quotation{
  font-size: 6rem;
  color: #f0535d;
  font-family:'Montagu Slab', serif;
  line-height: 1.8rem;
  padding-top: 2rem;
}

.about-testimonial-closing-quotation{
  font-size: 6rem;
  color: #f0535d;
  font-family:'Montagu Slab', serif;
  text-align: right;
  line-height: 2rem;
  padding-top: 2rem;
}

.about-testimonial-speaker {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 2rem;
  align-items: center;
  justify-content: center;
  gap: .2rem;
}

.about-testimonial-img-wrapper {
  flex-basis: auto;
}

.about-testimonial-company a {
  text-decoration: none;
  color: #386ac8;
  font-weight: 600;
}

.about-contact-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-sections-contact {
  margin: 0 auto 18% auto;
}

.about-contact-wrapper ul {
  display: flex;
  list-style: none;
  gap: 2rem;
  font-size: 2rem;
}

.about-contact-wrapper i {
  color: #35517a;
  transition: all .2s ease-in-out;
}

.about-contact-wrapper i:hover {
  color: #fb577b;
  /* color: #9aa7d9; */
  /* color: #bd71d4; */
  transform: scale(1.25)
}

.testimonial-even {
  flex-direction: row-reverse;
}

.about-contact-heading {
  text-align: center;
  margin-bottom: 1.5rem;
  letter-spacing: 2px;
  color: #2f2124;
  font-weight: 700;
}

.bold {
  font-weight: 600;
}

footer {
  font-size: .8rem;
  text-align: center;
  padding: 1rem 0 1.5rem;
  color: #2f2124;
}