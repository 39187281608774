@import "style_two.css";

*
{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}

@font-face {
    font-family: 'Futura';
    src: url("../static/fonts/futura.otf");
}

@font-face {
    font-family: 'Futura-light';
    src: url("../static/fonts/futura.light.otf");
}

html {
    /* do not use with gsap, messes it up */
    /* scroll-behavior: smooth; */
    font-size: 16px;
} 

@media (min-width: 0px) {
    html { font-size: 1rem; }
}
  
@media screen and (min-width: 320px) {
    html {
        font-size: calc(16px + 6 * ((100vw - 320px) / 680));
    }

}

@media screen and (min-width: 1000px) {
    html {
        font-size: 22px;
    }

}

body {
    font-family: 'Futura', sans-serif;
    vertical-align: baseline;
    overflow: hidden;
}

.webgl
{
    position: relative;
    outline: none;
    width: 100%;
}

header {
    width: 100%;
    position: fixed;
    z-index: 20;
    background: transparent;
    padding: 0 6.25%;
    color: #233245;
} 

#menu-section {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-top: 35px;
}

nav ul {
    display: flex;
    list-style-type: none;
    justify-content: right;
    gap: 1.5rem;
    text-align: right;
}


nav a {
    color: #233245;
    text-decoration: none;
    letter-spacing: 1.2px;
    transition: all .3s ease-in-out;
}

nav a:hover {
    color: #b45255;
}

nav a:active {
    font-weight: 700;
}

.name-logo {
    line-height: 1.5;
    letter-spacing: 1.2px;
    pointer-events: auto;
    font-weight: 600;
}

.section {
    position: absolute;
    top: 0%;
}

#overlay-loader {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: #000000;
    z-index: 50;
} 

/* FACE SVG */

#loading-svg {
    position: absolute;
    top: 28%;
    left: 35%;
    width: 27%;
}

#name-stroke {
    fill: none;
    stroke: #66fcf1;
    stroke-width: 4px;
}

#hair-stroke {
    stroke: #66fcf1;
    stroke-width: 3px;
    stroke-dasharray: 1373;
    stroke-dashoffset: 1373;
    transition: stroke-dashoffset .5s ease-in-out
}

.loading {
    fill: none;
    stroke: #fff;
    stroke-dasharray: 134; 
    stroke-dashoffset: 134;
    animation: 2s ease-in infinite load;
}

/* loading indicator */

@keyframes load {
    0% {
        stroke-dashoffset: 134;
        fill: none;
    }

    50% {
        stroke-dashoffset: 0;
        fill: #fff;
    }


    100% {
        stroke-dashoffset: 0;
        fill: #fff;
    }
}

.skin {
    fill: #ffeee6;
}

.skin {
stroke: #fff;
}

.eye-lip-white {
fill: #fff;
}
  
.rt-eye {
fill: url(#linear-gradient-5);
}

.lips, .lf-eye {
fill: url(#linear-gradient-6);
}

.rt-brow {
fill: url(#linear-gradient-7);
}

.lf-brow {
fill: url(#linear-gradient-8);
}

.nose {
fill: url(#linear-gradient-8);
}

#loading-meter {
fill:none;
}

/*************/

.label-text {
    color: #66fcf1;
    cursor: pointer;
    transition: all 0.5s;
}

.label-text:hover {
    color: #fff1e6;
}


.drag-note {
    margin-top: 1rem;
    color: pink;
    font-size: .8rem;
}

#direction {
    width: min(12vw, 250px);
}


#one {
    height: 50vh;
    position: fixed;
    top: 8%;
    width: 45vw;
}

.intro {
    padding-left: 4rem;
    padding-top: 6vh;
}

.intro-para {
    font-family: "Futura-light";
    font-size: calc(.6rem + 1vh);
    letter-spacing: .1rem;
    font-weight: 100;
    line-height: calc(1.6rem + 1vh);
    text-transform: none;
    padding-top: min(8vh, 5rem);

}

.page-transition-black {
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    background: #000;
    z-index: 30;
}

.page-transition-gold {
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    background: #d3ab73;
    z-index: 25;
}

.page-transition-logo {
    position: absolute;
    z-index: 35;
    top: 50vh;
    right: 50vw;
    transform: translate(100%, -50%);
}


/***********************
******** APPLICATIONS -SMALLER SCREEN
*************************/

@media screen and (max-width: 1025px) {

    .apps-carousel {
        display: none;
    }
    .menu-container {
        height: 80px;
    }

    .name-logo {
        font-size: 16px;
        cursor: pointer;
    }

    nav a {
        font-size: 16px;
    }

    .smaller-screen-app-carousel-nav {
        position: absolute;
        left: 0px;
        width: 100%;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -webkit-box-pack: center;
        top: 100px;
        justify-content: center;
    }
    .smaller-screen-app-carousel-nav-overlay {
        position: absolute;
        left: 0px;
        width: 100%;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -webkit-box-pack: center;
        top: 100px;
        justify-content: center;
        z-index: 20;
    }

    .smaller-screen-app-indicator-buttons {
        display: flex;
        gap: 1.5rem;
    }
    .smaller-screen-app-indicator-buttons-overlay {
        display: flex;
        gap: 1.5rem;
    }

    .smaller-screen-indicator-button {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        transform: translate3d(0px, -50%, 0px);
        border-radius: 4px;
        background: #000000;
        cursor: pointer;
        opacity: .4;
    }

    #smaller-screen-indicator-button-overlay-0{
        background: #27a3e4;
    }
    #smaller-screen-indicator-button-overlay-1{
        background: #33facf;
    }
    #smaller-screen-indicator-button-overlay-2{
        background: #eae172;
    }

    .smaller-screen-indicator-button-overlay {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        transform: translate3d(0px, -50%, 0px);
        border-radius: 4px;
        cursor: pointer;
        opacity: 0
    }

    .smaller-screen-indicator-button-selected {
        opacity: 1
    }

    #apps-image-background {
        display: block;
        position: absolute;
        top: 0px;
        right: 0;
        width: 100%;
        height: 100%;
        background: #fff;
    }

    .apps-image-carousel {
        display: block;
        position: absolute;
        top: 0px;
        right: 0;
        width: 100%;
        height: 100%;
        padding: 0px 4.7vw;
        overflow: hidden;
    }

    .apps-image-container {
        width: 100%;
        max-width: 75vh;
        margin: 0 auto;
        display: flex;
        align-items: stretch;
        -webkit-box-align: stretch;
        justify-content: flex-start;
        flex-flow: row nowrap;
        pointer-events: auto;
        user-select: none;
    }

    .apps-image-wrapper {
        flex: 0 0 auto;
        position: relative;
        width: 100%;
    }


    .apps-image-text-wrapper{
        width: 100%;
        margin-top: 150px;
        padding: 0px 1.5vw;
    }

    .apps-image-sub-wrapper {
        position: relative;
        width: 100%;
        transform-origin: center bottom;
        padding-bottom: 60%;

    }

    .apps-image-subtext-wrapper {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        /* -webkit-box-align: center; */
        align-items: flex-start;
        -webkit-box-pack: center;
        justify-content: flex-start;
        height: 20vh;
        font-size: 8vmin;
        padding-top: 5vh;
    }

    .apps-image-subtext {
        margin-top: 8px;
        line-height: 1.1em;
        overflow: visible;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .apps-image-subcontent {
        width: 100%;
        margin-top: 3.5vmin;
        display: block;
        font-size: 18px;
        line-height: 1.5;
    }

    .apps-image-subtext-0 {
        color: #27a3e4;
    }
    .apps-image-subtext-1 {
        color:  #33facf;
    }
    .apps-image-subtext-2 {
        color:  #eae172;
    }
    .apps-image-subcontent-1 {
        color: #ffffff;
    }

    .apps-image-item {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    /* APP Contents 1025px*/    

    .app-content-feature-subwrapper {
        font-size: 16px;
    }

    .app-content-heading-wrapper {
        font-size: 40px;
        margin-bottom: 60px;
    }
    
    .app-content-featured-wrapper {
        position: relative;
        max-width: 66%;
        flex: 0 0 66%;
        right: auto;
        left: auto;
        margin-left: 8%;
    }

    .app-content-tech-stack-wrapper {
        position: relative;
        flex: 0 0 33.33%;
        max-width: 33.33%;
        margin-left: 8%;
        right: auto;
        left: auto;
    }

    .app-content-description-wrapper {
        position: relative;
        flex: 0 0 50%;
        max-width: 50%;
        margin-left: 8%;
        right: auto;
        left: auto;
    }    
    
    .app-content-image-wrapper {
        margin: 60px auto 200px auto;
    }

    .app-nav-prev-back {
        font-size: 16px;
        letter-spacing: 1px;
    }

    /* ABOUT */
    .about-testimonial-content {
        flex: 1 1 100%;
        right: auto;
        left: auto;
    }
    
    .about-testimonial-speaker {
        flex: 1 1 100%;
        right: auto;
        left: auto;
    }


}

/***
*** APP DESCRIPTION SMALLER SCREEN
****/

@media screen and (max-width: 960px){
    /* HOME */
    .hero-text {
        left: 48%;
    }

    .my-name {
        font-size: 2.5rem;
    }

    .explore-button-wrapper {
        position: absolute;
        width: 100%;
        top: 310px;
        padding-left: 1rem;
    }

    .explore-button {
        max-width: 200px;
        line-height: 1.2rem;
        padding: .75rem;
        font-size: .8rem;
    }
    .my-title {
        font-size: 1.3rem;
    }


    /* APPS */

    .apps-image-subtext-wrapper {
        padding-top: 3vh;
        font-size: 2.5rem;
    }

    .app-content-feature-subwrapper {
        font-size: 14px;
    }

    .app-content-heading-wrapper {
        margin-bottom: 30px;
        font-size: 30px;
    }

    .app-content-image-wrapper {
        margin: 40px auto 150px auto;
    }

    .app-content-featured-wrapper {
        position: relative;
        max-width: 91%;
        flex: 0 0 91%;
        right: auto;
        left: auto;
        margin-left: 5%;
    }

    .app-content-description-wrapper {
        position: relative;
        flex: 0 0 91%;
        max-width: 91%;
        margin-left: 5%;
        right: auto;
        left: auto;
        margin-bottom: 30px;
    }

    .app-content-tech-stack-wrapper {
        position: relative;
        flex: 0 0 91%;
        max-width: 91%;
        margin-left: 5%;
        right: auto;
        left: auto;
    }    
    
    .app-content-description {
        font-size: 20px;
    }

    .app-nav-prev-back {
        font-size: 14px;
    }

    /*ABOUT*/
    .about-testimonials-wrapper h2{
        font-size: .9rem;
        letter-spacing: 2px;
    }

    .about-testimonials-wrapper h3{
        font-size: 1.6rem;
    }

    .about-testimonial-content {
        flex: 1 1 90%;
        right: auto;
        left: auto;
    }
    
    .about-testimonial-speaker {
        flex: 1 1 90%;
        right: auto;
        left: auto;
    }

    .about-tools-item {
        width: 45%;
    }

}

/* Desktop Screen */
@media screen and (min-width: 1025px) {
    /* home */
    .explore-button-wrapper {
        position: absolute;
        width: 55%;
        top: 48vh;
        padding-left: 1rem;
    }
    .explore-button {
        line-height: 1.5rem;
    }

    /* apps */
    #apps-image-background {
        display: none;
    }

    .name-logo {
        font-size: 16px;
        cursor: pointer;
    }

    nav a {
        font-size: 16px;
    }

    .apps-carousel {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 100%;
        height: 100%;
        padding-top: 52px;
        padding-bottom: 10vh;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        -webkit-box-align: center;
        -webkit-box-pack: start;
    }
    .app-hero-item {
        width: 100%;
        padding: 0px 6.25%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        display: none;
    }

    #app-hero-item-0 h1 {
        /* color: #68beea; */
        /* color: #129ae0 */
        color: #27a3e4;
    }

    #app-hero-item-1 h1 {
        color: #33facf;
        
    }

    #app-hero-item-1 p {
        color: #fff;
    }

    #app-hero-item-1 span {
        color: #ffffcc;
    }

    .app-hero-item-selected {
        display: block;
        z-index: 5;
    }

    .app-hero-text {
        color: #eae172;
        margin-bottom: 3.5vw;
        line-height: 1em;
        font-size: 5vw;
    }

    .app-subhero-wrapper {
        max-width: 500px;
        font-size: 20px;
        font-weight: 400;
        line-height: 1.6em;
        overflow: hidden;
    }

    .app-cta-wrapper {
        max-width: 500px;
        margin-top: 34px;
        display: inline-block;
        font-size: 20px;
        line-height: 1.5em;
        letter-spacing: 2px;
        font-weight: 700;
    }


    .app-carousel-nav {
        padding: 0px 6.25%;
        width: 100%;
        position: absolute;
        top: 46%;
        right: 0;
    }

    .app-indicator-wrapper {
        position: relative;
        margin-left: auto;
        margin-right: auto;
    }

    .app-indicator-flex {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .app-indicator-buttons {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        width: 35px;
        vertical-align: baseline;
        gap: 1rem;
    }

    .indicator-button {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        transform: translate3d(0px, -50%, 0px);
        border-radius: 4px;
        background: rgba(255,255,255, 0.3);
        cursor: pointer;
    }

    /* APP Contents */
    .app-content-box {
        padding: 0px 20px;
    }

    .app-content-feature-subwrapper {
        font-size: 18px;
    }

    .app-content-heading-wrapper {
        font-size: 50px;
        margin-bottom: 60px;
    }

    .app-content-image-wrapper {
        margin: 80px auto 160px auto;
    }

    .app-content-featured-wrapper {
        position: relative;
        max-width: 66%;
        flex: 0 0 66%;
        right: auto;
        left: auto;
        margin-left: 8%;
    }

    .app-content-tech-stack-wrapper {
        position: relative;
        flex: 0 0 33.33%;
        max-width: 33.33%;
        margin-left: 8%;
        right: auto;
        left: auto;
    }

    .app-content-description-wrapper {
        position: relative;
        flex: 0 0 50%;
        max-width: 50%;
        margin-left: 8%;
        right: auto;
        left: auto;
    }

    .app-nav-prev-back {
        font-size: 16px;
    }

    /*ABOUT*/
    .about-testimonials-wrapper h2{
        font-size: .9rem;
        letter-spacing: 2px;
    }
    .about-testimonial-content {
        flex: 1 1 66%;
    }
    
    .about-testimonial-speaker {
        flex: 1 1 33%;
    }

}

/* Desktop full wide */
@media screen and (min-width: 2000px) {
    #loading-svg {
        width: 30%;
        left: 30%;
    }

    .app-hero-text {
        font-size: 5.5vw;
    }

    .app-subhero-wrapper {
        max-width: 700px;
        font-size: 24px;
    }

    .app-cta-wrapper {
        font-size: 24px;
    }

    .name-logo {
        font-size: 22px;
        cursor: pointer;
    }

    nav a {
        font-size: 22px;
    }

    .app-content-heading-wrapper {
        font-size: 55px;
        margin-bottom: 60px;
    }

    .app-content-image-wrapper {
        margin: 60px auto 200px auto;
    }

    .explore-button {
        max-width: 480px
    }

    .about-tools-item {
        width: 25%;
    }
}


/* This has to be here due to cascading effect */
@media screen and (min-height: 500px) and (max-width: 600px) {
    #loading-svg {
        width: 55%;
        left: 18%;
    }
    
    /* HOME */
    .explore-button-wrapper {
        margin-top: 3rem;
    }
    .explore-button {
        max-width: 300px;
    }
    .hero-text {
        padding: 0 6.25%;
        width: 100vw;
        left: 0;
    }

    .my-name-wrapper {
        top: 1%;
    }
    .hi {
        font-size: 1.5rem;
    }
    .my-name-wrapper {
        padding-left: 0;
    }
    .my-name {
        font-size: 3rem;
        margin-top: .5rem;
    }
    .my-title {
        margin-top: .5rem;
        font-size: 1.5rem;
    }
    .explore-button-wrapper {
        position: absolute;
        top: 50%;
        left: 30%;
        width: 40%;
    }
    .explore-button {
        line-height: 1.5rem;
    }

    /* APPS */
    .apps-image-subtext {
        margin-top: 40px;
    }
    .apps-image-subcontent {
        font-size: 16px;
    }

    .name-logo {
        font-size: 14px;
        cursor: pointer;
    }

    nav a {
        font-size: 14px;
    }

    .menu-container {
        height: 58px;
    }

    .home-link {
        display: none;
    }

    /* App Section */

    .apps-image-subtext-wrapper {
        padding-top: .5vh;
    }

    .app-content-box {
        padding: 0px 5px;
    }

    .app-content-heading-wrapper {
        font-size: 25px;
        margin-bottom: 30px;
    }

    .app-content-image-wrapper {
        margin: 35px auto 125px auto;
    }

    .app-content-description-wrapper {
        position: relative;
        flex: 0 0 100%;
        max-width: 100%;
        margin-left: 0%;
        right: auto;
        left: auto;
        margin-bottom: 40px;
    }
    
    .app-content-tech-stack-wrapper {
        position: relative;
        flex: 0 0 100%;
        max-width: 100%;
        margin-left: 0%;
        right: auto;
        left: auto;
    }

    .app-content-feature-subwrapper {
        font-size: 14px;
    }

    .app-content-description {
        font-size: 16px;
    }

    .app-content-featured-wrapper {
        position: relative;
        max-width: 100%;
        flex: 0 0 100%;
        right: auto;
        left: auto;
        margin-left: 0%;
    }

    .app-nav-bar {
        margin-top: 5rem;
    }

    /*ABOUT*/
    .about-testimonials-wrapper h2{
        font-size: .75rem;
        letter-spacing: 1px;
    }

    .about-testimonial-content {
        flex: 1 1 100%;
        right: auto;
        left: auto;
    }
    
    .about-testimonial-speaker {
        flex: 1 1 100%;
        right: auto;
        left: auto;
    }

    .about-testimonial-opening-quotation{
        font-size: 5rem;
    }

    .about-testimonial-closing-quotation{
        font-size: 5rem;
    }

    .testimonial-even {
        flex-direction: row;
      }
}
